const ShoppingIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_9425_139592"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9425_139592)">
        <path
          d="M6.3077 21.5C5.80257 21.5 5.375 21.325 5.025 20.975C4.675 20.625 4.5 20.1974 4.5 19.6923V8.3077C4.5 7.80257 4.675 7.375 5.025 7.025C5.375 6.675 5.80257 6.5 6.3077 6.5H8.25V6.25C8.25 5.2141 8.61603 4.33013 9.34808 3.59808C10.0801 2.86603 10.9641 2.5 12 2.5C13.0359 2.5 13.9198 2.86603 14.6519 3.59808C15.3839 4.33013 15.75 5.2141 15.75 6.25V6.5H17.6922C18.1974 6.5 18.625 6.675 18.975 7.025C19.325 7.375 19.5 7.80257 19.5 8.3077V19.6923C19.5 20.1974 19.325 20.625 18.975 20.975C18.625 21.325 18.1974 21.5 17.6922 21.5H6.3077ZM6.3077 20H17.6922C17.7692 20 17.8397 19.9679 17.9038 19.9038C17.9679 19.8397 18 19.7692 18 19.6923V8.3077C18 8.23077 17.9679 8.16024 17.9038 8.09613C17.8397 8.03203 17.7692 7.99998 17.6922 7.99998H15.75V10.25C15.75 10.4628 15.6782 10.641 15.5346 10.7846C15.391 10.9282 15.2128 11 15 11C14.7872 11 14.609 10.9282 14.4654 10.7846C14.3218 10.641 14.25 10.4628 14.25 10.25V7.99998H9.74995V10.25C9.74995 10.4628 9.67816 10.641 9.53457 10.7846C9.39099 10.9282 9.21279 11 8.99997 11C8.78716 11 8.60896 10.9282 8.46537 10.7846C8.32179 10.641 8.25 10.4628 8.25 10.25V7.99998H6.3077C6.23077 7.99998 6.16024 8.03203 6.09612 8.09613C6.03202 8.16024 5.99997 8.23077 5.99997 8.3077V19.6923C5.99997 19.7692 6.03202 19.8397 6.09612 19.9038C6.16024 19.9679 6.23077 20 6.3077 20ZM9.74995 6.5H14.25V6.25C14.25 5.62307 14.0317 5.09133 13.5952 4.6548C13.1586 4.21825 12.6269 3.99998 12 3.99998C11.373 3.99998 10.8413 4.21825 10.4048 4.6548C9.96822 5.09133 9.74995 5.62307 9.74995 6.25V6.5Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default ShoppingIcon;
