const ShoppingMobileIcon = () => {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.34375"
        y="0.59375"
        width="36.8125"
        height="36.8125"
        rx="18.4062"
        fill="#F3F3F3"
      />
      <rect
        x="1.34375"
        y="0.59375"
        width="36.8125"
        height="36.8125"
        rx="18.4062"
        stroke="#E8E9E9"
        strokeWidth="1.1875"
      />
      <mask
        id="mask0_13089_153318"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="29"
        height="30"
      >
        <rect x="5.5" y="4.75" width="28.5" height="28.5" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13089_153318)">
        <path
          d="M12.9904 30.2812C12.3905 30.2812 11.8828 30.0734 11.4672 29.6578C11.0516 29.2421 10.8438 28.7344 10.8438 28.1345V14.6154C10.8438 14.0155 11.0516 13.5078 11.4672 13.0922C11.8828 12.6766 12.3905 12.4688 12.9904 12.4688H15.2969V12.1719C15.2969 10.9417 15.7315 9.89202 16.6008 9.02272C17.4701 8.15341 18.5198 7.71875 19.75 7.71875C20.9801 7.71875 22.0298 8.15341 22.8991 9.02272C23.7684 9.89202 24.2031 10.9417 24.2031 12.1719V12.4688H26.5095C27.1094 12.4688 27.6171 12.6766 28.0328 13.0922C28.4484 13.5078 28.6562 14.0155 28.6562 14.6154V28.1345C28.6562 28.7344 28.4484 29.2421 28.0328 29.6578C27.6171 30.0734 27.1094 30.2812 26.5095 30.2812H12.9904ZM12.9904 28.5H26.5095C26.6009 28.5 26.6847 28.4619 26.7608 28.3858C26.8369 28.3097 26.875 28.2259 26.875 28.1345V14.6154C26.875 14.524 26.8369 14.4403 26.7608 14.3641C26.6847 14.288 26.6009 14.25 26.5095 14.25H24.2031V16.9219C24.2031 17.1746 24.1178 17.3862 23.9473 17.5567C23.7768 17.7272 23.5652 17.8125 23.3125 17.8125C23.0598 17.8125 22.8481 17.7272 22.6776 17.5567C22.5071 17.3862 22.4219 17.1746 22.4219 16.9219V14.25H17.0781V16.9219C17.0781 17.1746 16.9928 17.3862 16.8223 17.5567C16.6518 17.7272 16.4402 17.8125 16.1875 17.8125C15.9348 17.8125 15.7231 17.7272 15.5526 17.5567C15.3821 17.3862 15.2969 17.1746 15.2969 16.9219V14.25H12.9904C12.899 14.25 12.8153 14.288 12.7391 14.3641C12.663 14.4403 12.625 14.524 12.625 14.6154V28.1345C12.625 28.2259 12.663 28.3097 12.7391 28.3858C12.8153 28.4619 12.899 28.5 12.9904 28.5ZM17.0781 12.4688H22.4219V12.1719C22.4219 11.4274 22.1627 10.796 21.6443 10.2776C21.1259 9.75917 20.4945 9.49997 19.75 9.49997C19.0055 9.49997 18.3741 9.75917 17.8557 10.2776C17.3373 10.796 17.0781 11.4274 17.0781 12.1719V12.4688Z"
          fill="#323941"
        />
      </g>
    </svg>
  );
};
export default ShoppingMobileIcon;
