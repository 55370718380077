import Arrow from "@/assets/icon/upDown.svg";


const UpDownIcon = () => {
    return (
<Arrow />
    );
  };
  export default UpDownIcon;
  
