const CloseIcon = ({color}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00047 8.0543L1.92737 13.1274C1.78892 13.2658 1.61489 13.3367 1.40527 13.3399C1.19567 13.3431 1.01844 13.2723 0.873575 13.1274C0.728692 12.9825 0.65625 12.8069 0.65625 12.6005C0.65625 12.3941 0.728692 12.2184 0.873575 12.0736L5.94665 7.00047L0.873575 1.92737C0.735108 1.78892 0.664275 1.61489 0.661075 1.40527C0.657858 1.19568 0.728692 1.01844 0.873575 0.873575C1.01844 0.728692 1.19407 0.65625 1.40047 0.65625C1.60687 0.65625 1.78251 0.728692 1.92737 0.873575L7.00047 5.94665L12.0736 0.873575C12.212 0.735108 12.3861 0.664275 12.5957 0.661075C12.8053 0.657858 12.9825 0.728692 13.1274 0.873575C13.2723 1.01844 13.3447 1.19407 13.3447 1.40047C13.3447 1.60687 13.2723 1.78251 13.1274 1.92737L8.0543 7.00047L13.1274 12.0736C13.2658 12.212 13.3367 12.3861 13.3399 12.5957C13.3431 12.8053 13.2723 12.9825 13.1274 13.1274C12.9825 13.2723 12.8069 13.3447 12.6005 13.3447C12.3941 13.3447 12.2184 13.2723 12.0736 13.1274L7.00047 8.0543Z"
        fill={color || "#ED1C24"}
      />
    </svg>
  );
};
export default CloseIcon;
