const DownloadArrow = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 19.5C1.0375 19.5 0.859375 19.428 0.715625 19.2842C0.571875 19.1404 0.5 18.9622 0.5 18.7496C0.5 18.537 0.571875 18.3589 0.715625 18.2154C0.859375 18.0718 1.0375 18 1.25 18H14.75C14.9625 18 15.1406 18.0719 15.2843 18.2157C15.4281 18.3595 15.5 18.5377 15.5 18.7503C15.5 18.9629 15.4281 19.141 15.2843 19.2846C15.1406 19.4282 14.9625 19.5 14.75 19.5H1.25ZM7.99035 15.0384C7.85478 15.0384 7.72297 15.0083 7.59492 14.948C7.46689 14.8878 7.35768 14.7974 7.2673 14.6769L2.7981 8.85575C2.57373 8.55449 2.54422 8.23559 2.70955 7.89905C2.87488 7.5625 3.14542 7.39423 3.52117 7.39423H5.3173V1.40385C5.3173 1.14777 5.40392 0.933109 5.57715 0.759876C5.7504 0.586626 5.96507 0.5 6.22115 0.5H9.7692C10.0253 0.5 10.2399 0.586626 10.4132 0.759876C10.5864 0.933109 10.673 1.14777 10.673 1.40385V7.39423H12.4692C12.8449 7.39423 13.1155 7.5625 13.2808 7.89905C13.4461 8.23559 13.4166 8.55449 13.1922 8.85575L8.71342 14.6769C8.62304 14.7974 8.51383 14.8878 8.3858 14.948C8.25775 15.0083 8.12593 15.0384 7.99035 15.0384ZM7.99035 13.1731L11.2269 8.90383H9.17308V1.99998H6.81727V8.90383H4.7634L7.99035 13.1731Z"
        fill="white"
      />
    </svg>
  );
};
export default DownloadArrow;
