const ArrowDownNew = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7409_28861"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7409_28861)">
        <path
          d="M12.4997 14.6769C12.3791 14.6769 12.267 14.6577 12.1631 14.6192C12.0593 14.5807 11.9606 14.5147 11.867 14.4211L7.37276 9.92689C7.23429 9.78844 7.16346 9.6144 7.16026 9.40479C7.15704 9.19519 7.22788 9.01795 7.37276 8.87309C7.51763 8.7282 7.69326 8.65576 7.89966 8.65576C8.10606 8.65576 8.28169 8.7282 8.42656 8.87309L12.4997 12.9462L16.5728 8.87309C16.7112 8.73462 16.8852 8.66379 17.0949 8.66059C17.3045 8.65737 17.4817 8.7282 17.6266 8.87309C17.7714 9.01795 17.8439 9.19359 17.8439 9.39999C17.8439 9.60639 17.7714 9.78202 17.6266 9.92689L13.1323 14.4211C13.0388 14.5147 12.94 14.5807 12.8362 14.6192C12.7324 14.6577 12.6202 14.6769 12.4997 14.6769Z"
          fill="#002F6E"
        />
      </g>
    </svg>
  );
};
export default ArrowDownNew;
