const CartIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="49" height="49" rx="24.5" fill="#F3F3F3" />
      <rect x="0.5" y="0.5" width="49" height="49" rx="24.5" stroke="#F3F3F3" />
      <mask
        id="mask0_1321_2450"
        maskUnits="userSpaceOnUse"
        x="9"
        y="9"
        width="32"
        height="32"
      >
        <rect x="9" y="9" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1321_2450)">
        <path
          d="M17.0855 37.6673C16.5124 37.6673 16.0216 37.4629 15.6129 37.0543C15.2043 36.6457 15 36.1549 15 35.5818V19.7528C15 19.1797 15.2043 18.6889 15.6129 18.2803C16.0216 17.8716 16.5124 17.6673 17.0855 17.6673H20.1624V17.1716C20.1624 15.8235 20.6316 14.6802 21.5701 13.7417C22.5085 12.8032 23.6518 12.334 25 12.334C26.3481 12.334 27.4914 12.8032 28.4299 13.7417C29.3683 14.6802 29.8375 15.8235 29.8375 17.1716V17.6673H32.9145C33.4875 17.6673 33.9784 17.8716 34.387 18.2803C34.7956 18.6889 34.9999 19.1797 34.9999 19.7528V35.5818C34.9999 36.1549 34.7956 36.6457 34.387 37.0543C33.9784 37.4629 33.4875 37.6673 32.9145 37.6673H17.0855ZM17.0855 35.9921H32.9145C33.017 35.9921 33.1111 35.9493 33.1965 35.8639C33.282 35.7784 33.3248 35.6844 33.3248 35.5818V19.7528C33.3248 19.6502 33.282 19.5562 33.1965 19.4707C33.1111 19.3852 33.017 19.3425 32.9145 19.3425H29.8375V22.5049C29.8375 22.7427 29.757 22.9417 29.5959 23.102C29.4348 23.2623 29.2348 23.3425 28.9959 23.3425C28.757 23.3425 28.5584 23.2623 28.4 23.102C28.2416 22.9417 28.1624 22.7427 28.1624 22.5049V19.3425H21.8375V22.5049C21.8375 22.7427 21.757 22.9417 21.5959 23.102C21.4348 23.2623 21.2348 23.3425 20.9959 23.3425C20.757 23.3425 20.5584 23.2623 20.4 23.102C20.2416 22.9417 20.1624 22.7427 20.1624 22.5049V19.3425H17.0855C16.9829 19.3425 16.8889 19.3852 16.8034 19.4707C16.7179 19.5562 16.6752 19.6502 16.6752 19.7528V35.5818C16.6752 35.6844 16.7179 35.7784 16.8034 35.8639C16.8889 35.9493 16.9829 35.9921 17.0855 35.9921ZM21.8375 17.6673H28.1624V17.1716C28.1624 16.2844 27.8571 15.5355 27.2466 14.925C26.636 14.3144 25.8871 14.0092 25 14.0092C24.1128 14.0092 23.3639 14.3144 22.7534 14.925C22.1428 15.5355 21.8375 16.2844 21.8375 17.1716V17.6673Z"
          fill="#60656B"
        />
      </g>
    </svg>
  );
};
export default CartIcon;
