const CarBlueIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.61532 13.5V14.4423C2.61532 14.7361 2.51239 14.9858 2.30652 15.1915C2.10064 15.3971 1.85065 15.5 1.55655 15.5C1.26243 15.5 1.01282 15.3971 0.8077 15.1915C0.602567 14.9858 0.5 14.7361 0.5 14.4423V7.38845C0.5 7.2846 0.506733 7.18075 0.5202 7.0769C0.533667 6.97305 0.556316 6.87359 0.58815 6.77853L2.37885 1.71538C2.49868 1.35528 2.71589 1.06269 3.03048 0.837625C3.34504 0.612542 3.69706 0.5 4.08653 0.5H13.9134C14.3029 0.5 14.6549 0.612542 14.9695 0.837625C15.2841 1.06269 15.5013 1.35528 15.6211 1.71538L17.4118 6.77853C17.4436 6.87359 17.4663 6.97305 17.4797 7.0769C17.4932 7.18075 17.5 7.2846 17.5 7.38845V14.4423C17.5 14.7361 17.397 14.9858 17.1911 15.1915C16.9852 15.3971 16.7353 15.5 16.4412 15.5C16.147 15.5 15.8974 15.3971 15.6923 15.1915C15.4872 14.9858 15.3846 14.7361 15.3846 14.4423V13.5H2.61532ZM2.60765 5.57695H15.3923L14.2077 2.20193C14.182 2.13781 14.1436 2.08813 14.0923 2.05288C14.041 2.01761 13.9801 1.99998 13.9096 1.99998H4.09038C4.01986 1.99998 3.95896 2.01761 3.90768 2.05288C3.85639 2.08813 3.81792 2.13781 3.79227 2.20193L2.60765 5.57695ZM4.46378 10.8461C4.82764 10.8461 5.13618 10.7188 5.38938 10.4641C5.64259 10.2093 5.7692 9.90004 5.7692 9.53618C5.7692 9.17231 5.64184 8.86378 5.38713 8.61058C5.13241 8.35736 4.82312 8.23075 4.45925 8.23075C4.09538 8.23075 3.78684 8.35811 3.53363 8.61283C3.28043 8.86754 3.15383 9.17683 3.15383 9.5407C3.15383 9.90457 3.28118 10.2131 3.5359 10.4663C3.79062 10.7195 4.09991 10.8461 4.46378 10.8461ZM13.5407 10.8461C13.9046 10.8461 14.2131 10.7188 14.4663 10.4641C14.7195 10.2093 14.8461 9.90004 14.8461 9.53618C14.8461 9.17231 14.7188 8.86378 14.4641 8.61058C14.2093 8.35736 13.9 8.23075 13.5362 8.23075C13.1723 8.23075 12.8638 8.35811 12.6106 8.61283C12.3574 8.86754 12.2308 9.17683 12.2308 9.5407C12.2308 9.90457 12.3581 10.2131 12.6128 10.4663C12.8675 10.7195 13.1768 10.8461 13.5407 10.8461ZM1.99997 12H16V7.0769H1.99997V12Z"
        fill="#002f6e"
      />
    </svg>
  );
};
export default CarBlueIcon;
