const CarIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11563_157533"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11563_157533)">
        <path
          d="M6.11532 18.5V19.4423C6.11532 19.7361 6.01239 19.9858 5.80652 20.1915C5.60064 20.3971 5.35065 20.5 5.05655 20.5C4.76243 20.5 4.51282 20.3971 4.3077 20.1915C4.10257 19.9858 4 19.7361 4 19.4423V12.3885C4 12.2846 4.00673 12.1808 4.0202 12.0769C4.03367 11.9731 4.05632 11.8736 4.08815 11.7785L5.87885 6.71538C5.99868 6.35528 6.21589 6.06269 6.53048 5.83763C6.84504 5.61254 7.19706 5.5 7.58653 5.5H17.4134C17.8029 5.5 18.1549 5.61254 18.4695 5.83763C18.7841 6.06269 19.0013 6.35528 19.1211 6.71538L20.9118 11.7785C20.9436 11.8736 20.9663 11.9731 20.9797 12.0769C20.9932 12.1808 21 12.2846 21 12.3885V19.4423C21 19.7361 20.897 19.9858 20.6911 20.1915C20.4852 20.3971 20.2353 20.5 19.9412 20.5C19.647 20.5 19.3974 20.3971 19.1923 20.1915C18.9872 19.9858 18.8846 19.7361 18.8846 19.4423V18.5H6.11532ZM6.10765 10.577H18.8923L17.7077 7.20193C17.682 7.13781 17.6436 7.08813 17.5923 7.05288C17.541 7.01761 17.4801 6.99998 17.4096 6.99998H7.59038C7.51986 6.99998 7.45896 7.01761 7.40768 7.05288C7.35639 7.08813 7.31792 7.13781 7.29227 7.20193L6.10765 10.577ZM7.96378 15.8461C8.32764 15.8461 8.63618 15.7188 8.88938 15.4641C9.14259 15.2093 9.2692 14.9 9.2692 14.5362C9.2692 14.1723 9.14184 13.8638 8.88713 13.6106C8.63241 13.3574 8.32312 13.2308 7.95925 13.2308C7.59538 13.2308 7.28684 13.3581 7.03363 13.6128C6.78043 13.8675 6.65383 14.1768 6.65383 14.5407C6.65383 14.9046 6.78118 15.2131 7.0359 15.4663C7.29062 15.7195 7.59991 15.8461 7.96378 15.8461ZM17.0407 15.8461C17.4046 15.8461 17.7131 15.7188 17.9663 15.4641C18.2195 15.2093 18.3461 14.9 18.3461 14.5362C18.3461 14.1723 18.2188 13.8638 17.9641 13.6106C17.7093 13.3574 17.4 13.2308 17.0362 13.2308C16.6723 13.2308 16.3638 13.3581 16.1106 13.6128C15.8574 13.8675 15.7308 14.1768 15.7308 14.5407C15.7308 14.9046 15.8581 15.2131 16.1128 15.4663C16.3675 15.7195 16.6768 15.8461 17.0407 15.8461ZM5.49997 17H19.5V12.0769H5.49997V17Z"
          fill="#494F55"
        />
      </g>
    </svg>
  );
};
export default CarIcon;
