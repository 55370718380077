const GreyCancelIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#F3F3F3" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#E8E9E9" />
      <mask
        id="mask0_3607_15399"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="24"
        height="24"
      >
        <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3607_15399)">
        <path
          d="M16.0005 17.0543L10.9274 22.1274C10.7889 22.2658 10.6149 22.3367 10.4053 22.3399C10.1957 22.3431 10.0184 22.2723 9.87357 22.1274C9.72869 21.9825 9.65625 21.8069 9.65625 21.6005C9.65625 21.3941 9.72869 21.2184 9.87357 21.0736L14.9466 16.0005L9.87357 10.9274C9.73511 10.7889 9.66427 10.6149 9.66107 10.4053C9.65786 10.1957 9.72869 10.0184 9.87357 9.87357C10.0184 9.72869 10.1941 9.65625 10.4005 9.65625C10.6069 9.65625 10.7825 9.72869 10.9274 9.87357L16.0005 14.9466L21.0736 9.87357C21.212 9.73511 21.3861 9.66427 21.5957 9.66107C21.8053 9.65786 21.9825 9.72869 22.1274 9.87357C22.2723 10.0184 22.3447 10.1941 22.3447 10.4005C22.3447 10.6069 22.2723 10.7825 22.1274 10.9274L17.0543 16.0005L22.1274 21.0736C22.2658 21.212 22.3367 21.3861 22.3399 21.5957C22.3431 21.8053 22.2723 21.9825 22.1274 22.1274C21.9825 22.2723 21.8069 22.3447 21.6005 22.3447C21.3941 22.3447 21.2184 22.2723 21.0736 22.1274L16.0005 17.0543Z"
          fill="#323941"
        />
      </g>
    </svg>
  );
};
export default GreyCancelIcon;
