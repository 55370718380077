const Dustbin = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="13.5" fill="white" stroke="#E8E9E9" />
      <mask
        id="mask0_3284_8560"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="20"
        height="20"
      >
        <rect x="4" y="4" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3284_8560)">
        <path
          d="M10.0897 21.0822C9.67549 21.0822 9.32085 20.9347 9.02585 20.6397C8.73084 20.3447 8.58333 19.99 8.58333 19.5758V8.99886H8.375C8.19792 8.99886 8.04948 8.93894 7.92969 8.81909C7.8099 8.69926 7.75 8.55076 7.75 8.37359C7.75 8.19644 7.8099 8.04804 7.92969 7.92839C8.04948 7.80872 8.19792 7.74888 8.375 7.74888H11.5C11.5 7.54483 11.5718 7.37096 11.7155 7.22726C11.8592 7.08357 12.0331 7.01172 12.2371 7.01172H15.7628C15.9669 7.01172 16.1407 7.08357 16.2844 7.22726C16.4281 7.37096 16.5 7.54483 16.5 7.74888H19.625C19.802 7.74888 19.9505 7.80881 20.0703 7.92866C20.1901 8.0485 20.25 8.197 20.25 8.37416C20.25 8.55132 20.1901 8.69973 20.0703 8.81939C19.9505 8.93904 19.802 8.99886 19.625 8.99886H19.4166V19.5758C19.4166 19.99 19.2691 20.3447 18.9741 20.6397C18.6791 20.9347 18.3245 21.0822 17.9102 21.0822H10.0897ZM18.1666 8.99886H9.83331V19.5758C9.83331 19.6506 9.85735 19.712 9.90544 19.7601C9.95352 19.8082 10.015 19.8322 10.0897 19.8322H17.9102C17.985 19.8322 18.0464 19.8082 18.0945 19.7601C18.1426 19.712 18.1666 19.6506 18.1666 19.5758V8.99886ZM12.4618 18.1655C12.639 18.1655 12.7874 18.1056 12.907 17.9858C13.0267 17.8661 13.0865 17.7176 13.0865 17.5406V11.2905C13.0865 11.1134 13.0266 10.965 12.9067 10.8452C12.7869 10.7254 12.6384 10.6655 12.4612 10.6655C12.2841 10.6655 12.1357 10.7254 12.016 10.8452C11.8964 10.965 11.8365 11.1134 11.8365 11.2905V17.5406C11.8365 17.7176 11.8965 17.8661 12.0163 17.9858C12.1361 18.1056 12.2846 18.1655 12.4618 18.1655ZM15.5387 18.1655C15.7159 18.1655 15.8643 18.1056 15.9839 17.9858C16.1036 17.8661 16.1634 17.7176 16.1634 17.5406V11.2905C16.1634 11.1134 16.1035 10.965 15.9836 10.8452C15.8638 10.7254 15.7153 10.6655 15.5381 10.6655C15.361 10.6655 15.2126 10.7254 15.0929 10.8452C14.9733 10.965 14.9134 11.1134 14.9134 11.2905V17.5406C14.9134 17.7176 14.9734 17.8661 15.0932 17.9858C15.2131 18.1056 15.3616 18.1655 15.5387 18.1655Z"
          fill="#323941"
        />
      </g>
    </svg>
  );
};
export default Dustbin;
