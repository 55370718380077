const CrossWhiteIcon = () => {
    return (

<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8.5791" r="8" fill="white"/>
<mask id="mask0_8155_72783" maskUnits="userSpaceOnUse" x="2" y="2" width="12" height="13">
<rect x="2" y="2.5791" width="12" height="12" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_8155_72783)">
<path d="M8.00024 9.10625L5.46369 11.6428C5.39446 11.712 5.30745 11.7474 5.20264 11.749C5.09784 11.7506 5.00922 11.7152 4.93679 11.6428C4.86435 11.5704 4.82812 11.4825 4.82812 11.3793C4.82812 11.2761 4.86435 11.1883 4.93679 11.1159L7.47332 8.57934L4.93679 6.04279C4.86755 5.97356 4.83214 5.88655 4.83054 5.78174C4.82893 5.67694 4.86435 5.58832 4.93679 5.51589C5.00922 5.44345 5.09704 5.40723 5.20024 5.40723C5.30344 5.40723 5.39125 5.44345 5.46369 5.51589L8.00024 8.05243L10.5368 5.51589C10.606 5.44666 10.693 5.41124 10.7978 5.40964C10.9026 5.40803 10.9913 5.44345 11.0637 5.51589C11.1361 5.58832 11.1723 5.67614 11.1723 5.77934C11.1723 5.88254 11.1361 5.97036 11.0637 6.04279L8.52715 8.57934L11.0637 11.1159C11.1329 11.1851 11.1683 11.2721 11.1699 11.3769C11.1715 11.4817 11.1361 11.5704 11.0637 11.6428C10.9913 11.7152 10.9034 11.7515 10.8002 11.7515C10.697 11.7515 10.6092 11.7152 10.5368 11.6428L8.00024 9.10625Z" fill="#323941"/>
</g>
</svg>

    );
  };
  export default CrossWhiteIcon;
  