const ArrowIcon = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6116 6.75081H1.25C1.03718 6.75081 0.858983 6.67902 0.7154 6.53543C0.5718 6.39185 0.5 6.21365 0.5 6.00083C0.5 5.78802 0.5718 5.60982 0.7154 5.46623C0.858983 5.32265 1.03718 5.25086 1.25 5.25086H12.6116L9.3192 1.95851C9.17048 1.80979 9.09709 1.63576 9.09902 1.43641C9.10094 1.23706 9.17433 1.05982 9.3192 0.904684C9.47433 0.749567 9.65253 0.669442 9.8538 0.664309C10.0551 0.659176 10.2333 0.734176 10.3884 0.889309L14.8672 5.36816C14.9608 5.46174 15.0269 5.56046 15.0653 5.66431C15.1038 5.76814 15.123 5.88032 15.123 6.00083C15.123 6.12135 15.1038 6.23353 15.0653 6.33736C15.0269 6.44121 14.9608 6.53993 14.8672 6.63351L10.3884 11.1124C10.2397 11.2611 10.0631 11.3345 9.85863 11.3326C9.65414 11.3306 9.47433 11.2521 9.3192 11.097C9.17433 10.9419 9.09933 10.7662 9.0942 10.5701C9.08908 10.3739 9.16408 10.1983 9.3192 10.0432L12.6116 6.75081Z"
        fill="#002F6E"
      />
    </svg>
  );
};
export default ArrowIcon;
